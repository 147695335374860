import React, { Component } from 'react';

class Landing extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
        <div className="Landing">
          Landing Page
        </div>
    );
  }
}

export default Landing;