import { getPartsAll, addPart } from '../javascripts/firebase'

export const loadParts = () => {
 return dispatch => {
  dispatch({
   type: "LOAD_REQUEST"
  })
  getPartsAll()
   .then(parts => {
    dispatch({
     type: "LOAD_SUCCESS",
     payload: parts.val()
    })
   })
   .catch(error => {
    dispatch({
     type: "LOAD_FAILED",
     payload: error
    })
   })
 }
}

export const createPart = (name) => {
 return dispatch => {
  dispatch({
   type: "ADD_REQUEST"
  })
  addPart(name)
   .then(res => {
    loadParts()(dispatch) //refresh the data to keep up-to-date
    dispatch({
     type: "ADD_SUCCESS"
    })
   })
   .catch(error => {
    dispatch({
     type: "ADD_FAILED",
     payload: error
    })
   })
 }
}