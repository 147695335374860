import _ from 'lodash'
let initialState = {
  parts: {}
}
export default (state = initialState, action) => {
  let newState = _.merge({}, state)

  switch(action.type) {
  	case "LOAD_SUCCESS":
		newState.parts = action.payload;
		return newState
    default:
      return state
  }
}