import firebase from 'firebase/app'
import 'firebase/database'
import partGroupModel from './models/partGroup'
import partModel from './models/part'

let database

export const init = () => {
  let config = {
    apiKey: "AIzaSyAUG1lrmByxLzLhlAx_JWYM0ahrNbedu78",
    authDomain: "fixit-95cfb.firebaseapp.com",
    databaseURL: "https://fixit-95cfb.firebaseio.com",
    projectId: "fixit-95cfb",
    storageBucket: "fixit-95cfb.appspot.com",
    messagingSenderId: "164811729736"
  }

  database = !firebase.apps.length 
  ? firebase.initializeApp(config).database()
  : firebase.database(firebase.app());
}

// get parts
export const getPartsAll = () => {
	return database.ref('/parts').once('value')
}

// add part
export const addPart = (title) => {
  let partsRef = database.ref().child('parts')
  let ref = partsRef.push()
  let model = partModel(ref.key, title, firebase.database.ServerValue.TIMESTAMP)
  return database.ref('/parts/' + ref.key).set(model)
}