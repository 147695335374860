import _ from 'lodash'
import React from 'react';
import Part from './part'

export default (props) => {
  return (
    <div className="row">
      {
        _.map(props.parts, (part) => <Part part={part} key={part.rh_id}/>)
      }
    </div>
  )
}