import React, { Component } from 'react';
import {init as firebaseInit} from '../javascripts/firebase'
import {Provider} from 'react-redux'
import configureStore from './configureStore'
import App from './App'

class Root extends Component {
  constructor(props) {
    super(props)
    firebaseInit()
    this.store = configureStore()
  }

  render() {
    return (
      <Provider store={this.store}>
        <App/>
      </Provider>
    );
  }
}

export default Root;