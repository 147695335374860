import React from 'react';
import NoImg from '../../../assets/no-image-small.jpg';

export default (props) => {
	function getImageSrc(part){
		return part.SmallImage || NoImg;
	}

	function getDiscontinuedClasses(part){
		return part && part.tags && part.tags.indexOf("discontinued") != -1 ? "discontinued" : "hidden";
	}

  return (
    <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2 part">
    	<div className={getDiscontinuedClasses(props.part)}>discontinued</div>
    	<div className="row">
	    	<div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
	    		<img src={getImageSrc(props.part)} className="" width="50" height="50" />
			</div>
	    	<div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">{props.part.Title}</div>
    	</div>
	</div>
  )
}