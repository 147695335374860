import React, { Component } from 'react';
import PartsList from './parts-list'
import {connect} from 'react-redux'
import {loadParts, createPart} from '../../../actions/righappy'
import logo from './logo.svg';
import './App.css';

class Home extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.loadParts()
  }

  onSubmit = (e) => {
    e.preventDefault()
    let ref = this.refs['part-name']
    let partName = ref.value
    this.props.createPart(partName)
    ref.value = ''
  }

  render() {
    return (
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <h1 className="App-title">Welcome to RigHappy</h1>
          </header>
          <h2>Enter a new part</h2>
          <div>
            <form onSubmit={this.onSubmit}>
              <input ref="part-name"/>
              <button>Add new part</button>
            </form>
          </div>
          <h2>Parts List</h2>
          <PartsList parts={this.props.parts}/>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    parts: state.righappy.parts
  }
}

export default connect(mapStateToProps, {loadParts, createPart})(Home)
